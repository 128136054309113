<template>
    <div class="signUP-admin">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-5 col-md-5 p-0">
                    <div class="signUP-admin-left signIn-admin-left position-relative">
                        <div class="signUP-overlay">
                            <img class="svg signupTop" src="@/assets/img/svg/signupTop.svg" alt="img" />
                            <img class="svg signupBottom" src="@/assets/img/svg/signupBottom.svg" alt="img" />
                        </div>
                        <div class="signUP-admin-left__content">
                            <h1>{{ $t("apps.app_name") }}</h1>
                            <h3>{{ $t("apps.app_description") }}</h3>
                        </div>
                        <div class="signUP-admin-left__img">
                            <img class="img-fluid svg" src="@/assets/img/svg/signupIllustration.svg" alt="img" />
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                    <div class="signUp-admin-right signIn-admin-right p-md-40 p-10">
                        <div
                            class="signUp-topbar d-flex align-items-center justify-content-md-end justify-content-center mt-md-0 mb-md-0 mt-20 mb-1">
                            <p class="mb-0">
                                {{ $t("auth.already_have_account") }}
                                <router-link to="/login" class="color-primary">
                                    {{ $t("auth.sign_in_now") }}
                                </router-link>
                                <br />
                                {{ $t("auth.doesnot_have_account") }}
                                <router-link to="/register" class="color-primary">
                                    {{ $t("auth.register_now") }}
                                </router-link>
                            </p>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-xl-7 col-lg-8 col-md-12">
                                <div class="edit-profile mt-md-25 mt-0">
                                    <div class="card border-0">
                                        <div class="card-header border-0 pb-md-15 pb-10 pt-md-20 pt-10">
                                            <div class="edit-profile__title">
                                                <h6>
                                                    {{ $t("auth.verify_otp") }}
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="edit-profile__body">
                                                <form method="POST" v-on:submit.prevent="verifyAccount">
                                                    <div class="form-group mb-20">
                                                        <label>
                                                            {{ $t("global.whatsapp_number") }}
                                                        </label>
                                                        <input type="text" class="form-control"
                                                            placeholder="08xxxxxxxxx" v-model="form.phone" required />
                                                        <span class="invalid-feedback" role="alert">
                                                            <strong></strong>
                                                        </span>
                                                    </div>
                                                    <div class="form-group mb-15">
                                                        <label>
                                                            {{ $t("global.otp_code") }}
                                                        </label>
                                                        <div class="position-relative">
                                                            <input type="text" class="form-control"
                                                                placeholder="Kode OTP" v-model="form.otp" required />
                                                            <span class="invalid-feedback" role="alert">
                                                                <strong></strong>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="signUp-condition signIn-condition">
                                                        <a style="color:black">{{ $t("auth.doesnot_receive_otp") }} <a style="cursor:pointer" v-on:click="resendOTP">{{ $t("auth.resend_otp") }}</a></a>
                                                    </div>
                                                    <div
                                                        class="button-group d-flex pt-1 justify-content-md-start justify-content-center">
                                                        <button v-if="isLoading"
                                                            class="btn btn-primary btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn"
                                                            disabled>
                                                            <div class="spinner-border spinner" role="status"></div>
                                                            {{ $t("auth.loading") }}
                                                        </button>
                                                        <button v-else
                                                            class="btn btn-primary btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn">
                                                            {{ $t("auth.verify") }}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                form: {
                    phone: "",
                    otp: "",
                },
                isLoading: false,
            };
        },
        mounted() {
            this.form.phone = this.$route.query.phone
        },
        methods: {
            async verifyAccount() {
                this.isLoading = true;

                try {
                    let verify = await this.$store.dispatch("auth_verify_otp", this.form);
                    this.$toast.success(verify.data.message);
                    await this.$router.push("/login");
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false;
            },
            async resendOTP() {
                this.isLoading = true;

                if (this.form.phone == "" || this.form.phone == null) {
                    this.$toast.error("Please input phone number");
                } else {
                    try {
                        let resend = await this.$store.dispatch("auth_resend_otp", this.form);
                        this.$toast.success(resend.data.message);
                    } catch (err) {
                        this.$toast.error(err.response.data.message);
                    }
                }

                this.isLoading = false;
            },
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn {
        line-height: 1rem !important;
    }
</style>