<template>
    <div class="signUP-admin">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-5 col-md-5 p-0">
                    <div class="signUP-admin-left signIn-admin-left position-relative">
                        <div class="signUP-overlay">
                            <img class="svg signupTop" src="@/assets/img/svg/signupTop.svg" alt="img" />
                            <img class="svg signupBottom" src="@/assets/img/svg/signupBottom.svg" alt="img" />
                        </div>
                        <div class="signUP-admin-left__content">
                            <h1>{{ $t("apps.app_name") }}</h1>
                            <h3>{{ $t("apps.app_description") }}</h3>
                        </div>
                        <div class="signUP-admin-left__img">
                            <img class="img-fluid svg" src="@/assets/img/svg/signupIllustration.svg" alt="img" />
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                    <div class="signUp-admin-right signIn-admin-right p-md-40 p-10">
                        <div
                            class="signUp-topbar d-flex align-items-center justify-content-md-end justify-content-center mt-md-0 mb-md-0 mt-20 mb-1">
                            <p class="mb-0">
                                {{ $t("auth.already_have_account") }}
                                <router-link to="/login" class="color-primary">
                                    {{ $t("auth.sign_in_now") }}
                                </router-link>
                                <br />
                                {{ $t("auth.need_verify_account") }}
                                <router-link to="/verify" class="color-primary">
                                    {{ $t("auth.verify_now") }}
                                </router-link>
                            </p>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-xl-12 col-lg-12 col-md-12">
                                <div class="edit-profile mt-md-25 mt-0">
                                    <div class="card border-0">
                                        <div class="card-header border-0 pb-md-15 pb-10 pt-md-20 pt-10">
                                            <div class="edit-profile__title">
                                                <h6>
                                                    {{ $t("auth.register_as") }}
                                                    <span class="color-primary">
                                                        {{ $t("global.admin") }}
                                                    </span>
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="edit-profile__body">
                                                <form method="POST" v-on:submit.prevent="signUp">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group mb-20">
                                                                <label for="username">
                                                                    {{ $t("global.name") }}
                                                                </label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Name" v-model="form.name" required />
                                                                <span class="invalid-feedback" role="alert">
                                                                    <strong></strong>
                                                                </span>
                                                            </div>
                                                            <div class="form-group mb-20">
                                                                <label>
                                                                    {{ $t("global.username") }}
                                                                </label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Username" v-model="form.username"
                                                                    required />
                                                                <span class="invalid-feedback" role="alert">
                                                                    <strong></strong>
                                                                </span>
                                                            </div>
                                                            <div class="form-group mb-20">
                                                                <label>
                                                                    {{ $t("global.email") }}
                                                                </label>
                                                                <input type="email" class="form-control"
                                                                    placeholder="name@example.com" v-model="form.email"
                                                                    required />
                                                                <span class="invalid-feedback" role="alert">
                                                                    <strong></strong>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group mb-15">
                                                                <label for="password-field">
                                                                    {{ $t("auth.password") }}
                                                                </label>
                                                                <div class="position-relative">
                                                                    <input id="password-field" :type="passwordFieldType"
                                                                        class="form-control" placeholder="Password"
                                                                        v-model="form.password" required />
                                                                    <a v-on:click="togglePassword" class="fa fa-fw fa-eye-slash text-light fs-16 field-icon toggle-password2"></a>
                                                                    <span class="invalid-feedback" role="alert">
                                                                        <strong></strong>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="form-group mb-20">
                                                                <label>
                                                                    {{ $t("global.whatsapp_number") }}
                                                                </label>
                                                                <input type="number" class="form-control"
                                                                    placeholder="08xxxxxxxxx" v-model="form.phone"
                                                                    required />
                                                                <span class="invalid-feedback" role="alert">
                                                                    <strong></strong>
                                                                </span>
                                                            </div>
                                                            <div class="form-group mb-20">
                                                                <label for="username">
                                                                    {{ $t("global.referral_code") }} ({{ $t("global.optional") }})
                                                                </label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Referral" v-model="form.referral" />
                                                                <span class="invalid-feedback" role="alert">
                                                                    <strong></strong>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="signUp-condition signIn-condition">
                                                        <div class="checkbox-theme-default custom-checkbox">
                                                            <input v-model="form.trust" class="checkbox" type="checkbox" id='check-1' required>
                                                            <label for="check-1">
                                                                <span class="checkbox-text">{{ $t("auth.privacy_policy_agreement") }}*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="button-group d-flex pt-1 justify-content-md-start justify-content-center">
                                                        <button v-if="isLoading"
                                                            class="btn btn-primary btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn"
                                                            disabled>
                                                            <div class="spinner-border spinner" role="status"></div>
                                                            {{ $t("auth.loading") }}
                                                        </button>
                                                        <button v-else
                                                            class="btn btn-primary btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn">
                                                            Daftar
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                form: {
                    name: "",
                    username: "",
                    email: "",
                    password: "",
                    phone: "",
                    referral: "",
                    trust: ""
                },
                passwordFieldType: "password",
                isLoading: false,
            };
        },
        mounted() {},
        methods: {
            async signUp() {
                this.isLoading = true;

                try {
                    console.log(this.form)
                    let register = await this.$store.dispatch("auth_register", this.form);
                    this.$toast.success(register.data.message);
                    await this.$router.push("/verify?phone=" + this.form.phone);
                    // await this.$root.$emit("login", true);
                    // //this.$toast.success("Login success");
                    // await this.$router.push("/dashboard");
                    // window.location.reload()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false;
            },
            togglePassword(){
                if(this.passwordFieldType == "password"){
                    this.passwordFieldType = "text"
                }else{
                    this.passwordFieldType = "password"
                }
            }
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn {
        line-height: 1rem !important;
    }
</style>